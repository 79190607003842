import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'mosha-vue-toastify/dist/style.css';

import * as stateActions from "./store/stateActions";

import ApiService from "./services/api.service";
import App from "@/App.vue";
import Maska from "maska";
import { VueCookieNext } from "vue-cookie-next";
import { createApp } from "vue";
import { createHead } from '@vueuse/head'
import i18n from './lang'
import moshaToast from 'mosha-vue-toastify';
import router from "@/router/index";
import { store } from "./store";

const head = createHead()

router.beforeEach((to: any, from, next) => {
    let token = null;

    if (to.query.token) {
        token = to.query.token;
    } else {
        localStorage.removeItem('token');
    }

    if (to.query.displayName) {
        let user = JSON.parse(localStorage.getItem('user'));

        if (!user) {
            user = {};
        }

        user.name = to.query.displayName;
        localStorage.setItem('user', JSON.stringify(user));
    }

    if (to.name === 'room' && token) {
        ApiService.checkToken(token).then(() => {
            store.dispatch(stateActions.setAdmin(1));
            next();
        }, () => {
            store.dispatch(stateActions.setAdmin(0));
            localStorage.removeItem('token');
            next({ name: 'welcome' })
        });
    } else {
        store.dispatch(stateActions.setAdmin(0));
        next();
    }
});

createApp(App)
    .use(i18n)
    .use(Maska)
    .use(head)
    .use(VueCookieNext)
    .use(moshaToast)
    .use(store)
    .use(router)
    .mount("#app");
