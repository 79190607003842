const consumers = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "closed") {
          state.consumers = {};
        }
      }

      break;

    case "ADD_CONSUMER":
      {
        const { consumer } = action.payload;

        state.consumers[consumer.id] = consumer;
      }

      break;

    case "REMOVE_CONSUMER":
      {
        const { consumerId } = action.payload;
        const newState = { ...state.consumers };

        delete newState[consumerId];

        state.consumers = newState;
      }

      break;

    case "SET_CONSUMER_PAUSED":
      {
        const { consumerId, originator } = action.payload;
        const consumer = state.consumers[consumerId];
        // let newConsumer;

        // if (originator === "local") {
        //   newConsumer = { ...consumer, locallyPaused: true };
        // } else {
        //   newConsumer = { ...consumer, remotelyPaused: true };
        // }

        // state.consumers[consumerId] = newConsumer;

        if (originator === "local") {
          consumer.locallyPaused = true ;
        } else {
          consumer.remotelyPaused = true;
        }
      }

      break;

    case "SET_CONSUMER_RESUMED":
      {
        const { consumerId, originator } = action.payload;
        const consumer = state.consumers[consumerId];
        let newConsumer;

        if (originator === "local") {
          newConsumer = { ...consumer, locallyPaused: false };
        } else {
          newConsumer = { ...consumer, remotelyPaused: false };
        }

        state.consumers[consumerId] = newConsumer;
      }

      break;

    case "SET_CONSUMER_CURRENT_LAYERS":
      {
        const { consumerId, spatialLayer, temporalLayer } = action.payload;
        const consumer = state.consumers[consumerId];

        const newConsumer = {
          ...consumer,
          currentSpatialLayer: spatialLayer,
          currentTemporalLayer: temporalLayer,
        };

        state.consumers[consumerId] = newConsumer;
      }

      break;

    case "SET_CONSUMER_PREFERRED_LAYERS":
      {
        const { consumerId, spatialLayer, temporalLayer } = action.payload;
        const consumer = state.consumers[consumerId];
        const newConsumer = {
          ...consumer,
          preferredSpatialLayer: spatialLayer,
          preferredTemporalLayer: temporalLayer,
        };

        state.consumers[consumerId] = newConsumer;
      }

      break;

    case "SET_CONSUMER_PRIORITY":
      {
        const { consumerId, priority } = action.payload;
        const consumer = state.consumers[consumerId];
        const newConsumer = { ...consumer, priority };

        state.consumers[consumerId] = newConsumer;
      }

      break;

    case "SET_CONSUMER_TRACK":
      {
        const { consumerId, track } = action.payload;
        const consumer = state.consumers[consumerId];
        const newConsumer = { ...consumer, track };

        state.consumers[consumerId] = newConsumer;
      }

      break;

    case "SET_CONSUMER_SCORE":
      {
        const { consumerId, score } = action.payload;
        const consumer = state.consumers[consumerId];

        if (!consumer) {
          return;
        }

        state.consumers[consumerId].score = score;
      }

      break;
  }
};

export default consumers;
