export const removePeer = (peerId) => {
  return {
    type: ["peers", "room"],
    action: {
      type: "REMOVE_PEER",
      payload: { peerId },
    },
  };
};

export const setRoomState = (state) => {
  return {
    type: [
      "consumers",
      "dataConsumers",
      "dataProducers",
      "me",
      "peers",
      "producers",
      "room",
    ],
    action: {
      type: "SET_ROOM_STATE",
      payload: { state },
    },
  };
};

export const addConsumer = (consumer, peerId) => {
  return {
    type: ["consumers", "peers"],
    action: {
      type: "ADD_CONSUMER",
      payload: { consumer, peerId },
    },
  };
};

export const removeConsumer = (consumerId, peerId) => {
  return {
    type: ["consumers", "peers"],
    action: {
      type: "REMOVE_CONSUMER",
      payload: { consumerId, peerId },
    },
  };
};

export const addDataConsumer = (dataConsumer: any, peerId: string): any => {
  return {
    type: ["dataConsumers", "peers"],
    action: {
      type: "ADD_DATA_CONSUMER",
      payload: { dataConsumer, peerId },
    },
  };
};

export const removeDataConsumer = (
  dataConsumerId: string,
  peerId: string
): any => {
  return {
    type: ["dataConsumers", "peers"],
    action: {
      type: "REMOVE_DATA_CONSUMER",
      payload: { dataConsumerId, peerId },
    },
  };
};

export const setRecord = (status, message = null) => {
  return {
    type: "room",
    action: {
      type: "SET_RECORD",
      payload: { status, message },
    }, 
  };
};

export const setRoomUrl = (url) => {
  return {
    type: "room",
    action: {
      type: "SET_ROOM_URL",
      payload: { url },
    },
  };
};

export const setRoomActiveSpeaker = (payload) => {
  return {
    type: "room",
    action: {
      type: "SET_ROOM_ACTIVE_SPEAKER",
      payload,
    },
  };
};

export const setRoomStatsPeerId = (peerId) => {
  return {
    type: "room",
    action: {
      type: "SET_ROOM_STATS_PEER_ID",
      payload: { peerId },
    },
  };
};

export const setRoomFaceDetection = (flag) => {
  return {
    type: "room",
    action: {
      type: "SET_FACE_DETECTION",
      payload: flag,
    },
  };
};

export const setMe = ({ peerId, displayName, displayNameSet, device }) => {
  return {
    type: "me",
    action: {
      type: "SET_ME",
      payload: { peerId, displayName, displayNameSet, device },
    },
  };
};

export const setMediaCapabilities = ({ canSendMic, canSendWebcam }) => {
  return {
    type: "me",
    action: {
      type: "SET_MEDIA_CAPABILITIES",
      payload: { canSendMic, canSendWebcam },
    },
  };
};

export const setCanChangeWebcam = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_CAN_CHANGE_WEBCAM",
      payload: flag,
    },
  };
};

export const setDisplayName = (displayName) => {
  return {
    type: "me",
    action: {
      type: "SET_DISPLAY_NAME",
      payload: { displayName },
    },
  };
};

export const setAudioOnlyState = (enabled) => {
  return {
    type: "me",
    action: {
      type: "SET_AUDIO_ONLY_STATE",
      payload: { enabled },
    },
  };
};

export const setAudioOnlyInProgress = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_AUDIO_ONLY_IN_PROGRESS",
      payload: { flag },
    },
  };
};

export const setMicState = (enabled) => {
  return {
    type: "me",
    action: {
      type: "SET_MIC_STATE",
      payload: { enabled },
    },
  };
};

export const setWebcamState = (enabled) => {
  return {
    type: "me",
    action: {
      type: "SET_WEBCAM_STATE",
      payload: { enabled },
    },
  };
};

export const setRefreshState = (flag) => {
  return {
    type: "room",
    action: {
      type: "SET_REFRESH_STATE",
      payload: { flag },
    },
  };
};

export const setRestartIceInProgress = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_RESTART_ICE_IN_PROGRESS",
      payload: { flag },
    },
  };
};

export const setRecordInProgress = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_RECORD_IN_PROGRESS",
      payload: { flag },
    },
  };
};

export const setPhoneFormOpened = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_PHONE_FORM_OPENED",
      payload: { flag },
    },
  };
};

export const setCodeFormOpened = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_CODE_FORM_OPENED",
      payload: { flag },
    },
  };
};

export const setOrientation = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_ORIENTATION",
      payload: { flag },
    },
  };
};

export const setGeo = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_GEO",
      payload: { flag },
    },
  };
};

export const setClientOrientation = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_CLIENT_ORIENTATION",
      payload: { flag },
    },
  };
};

export const setMaskType = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_MASK",
      payload: { flag },
    },
  };
};

export const setAdmin = (isAdmin: number): any => {
  return {
    type: "me",
    action: {
      type: "SET_ADMIN",
      payload: { isAdmin },
    },
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setProduct = (product: any): any => {
  return {
    type: "me",
    action: {
      type: "SET_PRODUCT",
      payload: { product },
    },
  };
};

export const addProducer = (producer) => {
  return {
    type: "producers",
    action: {
      type: "ADD_PRODUCER",
      payload: { producer },
    },
  };
};

export const removeProducer = (producerId) => {
  return {
    type: "producers",
    action: {
      type: "REMOVE_PRODUCER",
      payload: { producerId },
    },
  };
};

export const setProducerPaused = (producerId) => {
  return {
    type: "producers",
    action: {
      type: "SET_PRODUCER_PAUSED",
      payload: { producerId },
    },
  };
};

export const setProducerResumed = (producerId) => {
  return {
    type: "producers",
    action: {
      type: "SET_PRODUCER_RESUMED",
      payload: { producerId },
    },
  };
};

export const setProducerTrack = (producerId, track) => {
  return {
    type: "producers",
    action: {
      type: "SET_PRODUCER_TRACK",
      payload: { producerId, track },
    },
  };
};

export const setProducerScore = (producerId, score) => {
  return {
    type: "producers",
    action: {
      type: "SET_PRODUCER_SCORE",
      payload: { producerId, score },
    },
  };
};

export const addDataProducer = (dataProducer) => {
  return {
    type: "dataProducers",
    action: {
      type: "ADD_DATA_PRODUCER",
      payload: { dataProducer },
    },
  };
};

export const removeDataProducer = (dataProducerId) => {
  return {
    type: "dataProducers",
    action: {
      type: "REMOVE_DATA_PRODUCER",
      payload: { dataProducerId },
    },
  };
};

export const setWebcamInProgress = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_WEBCAM_IN_PROGRESS",
      payload: { flag },
    },
  };
};

export const setShareInProgress = (flag) => {
  return {
    type: "me",
    action: {
      type: "SET_SHARE_IN_PROGRESS",
      payload: { flag },
    },
  };
};

export const addPeer = (peer) => {
  return {
    type: "peers",
    action: {
      type: "ADD_PEER",
      payload: { peer },
    },
  };
};

export const setPeerDisplayName = (displayName, peerId) => {
  return {
    type: "peers",
    action: {
      type: "SET_PEER_DISPLAY_NAME",
      payload: { displayName, peerId },
    },
  };
};

export const setConsumerPaused = (consumerId, originator) => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_PAUSED",
      payload: { consumerId, originator },
    },
  };
};

export const setConsumerResumed = (consumerId, originator) => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_RESUMED",
      payload: { consumerId, originator },
    },
  };
};

export const setConsumerCurrentLayers = (
  consumerId: string,
  spatialLayer: number,
  temporalLayer: number
): any => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_CURRENT_LAYERS",
      payload: { consumerId, spatialLayer, temporalLayer },
    },
  };
};

export const setConsumerPreferredLayers = (
  consumerId: string,
  spatialLayer: number,
  temporalLayer: number
): any => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_PREFERRED_LAYERS",
      payload: { consumerId, spatialLayer, temporalLayer },
    },
  };
};

export const setConsumerPriority = (
  consumerId: string,
  priority: number
): any => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_PRIORITY",
      payload: { consumerId, priority },
    },
  };
};

export const setConsumerTrack = (consumerId: string, track: any): any => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_TRACK",
      payload: { consumerId, track },
    },
  };
};

export const setConsumerScore = (consumerId: string, score: number): any => {
  return {
    type: "consumers",
    action: {
      type: "SET_CONSUMER_SCORE",
      payload: { consumerId, score },
    },
  };
};

export const addNotification = (notification: any): any => {
  return {
    type: "notifications",
    action: {
      type: "ADD_NOTIFICATION",
      payload: { notification },
    },
  };
};
