const producers = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "ADD_PRODUCER":
      {
        const { producer } = action.payload;

        state.producers[producer.id] = producer;
      }

      break;

    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "closed") {
          state.producers = {};
        }
      }

      break;

    case "REMOVE_PRODUCER":
      {
        const { producerId } = action.payload;

        delete state.producers[producerId];
      }

      break;

    case "SET_PRODUCER_PAUSED":
      {
        const { producerId } = action.payload;

        state.producers[producerId].paused = true;
      }

      break;

    case "SET_PRODUCER_RESUMED":
      {
        const { producerId } = action.payload;

        state.producers[producerId].paused = false;
      }

      break;

    case "SET_PRODUCER_TRACK":
      {
        const { producerId, track } = action.payload;
        const producer = state.producers[producerId];
        state.producers[producerId] = { ...producer, track };
      }

      break;

    case "SET_PRODUCER_SCORE":
      {
        const { producerId, score } = action.payload;
        const producer = state.producers[producerId];

        if (!producer) {
          return;
        }

        state.producers[producerId] = { ...producer, score };
      }

      break;
  }
};

export default producers;
