
import { computed, defineComponent, reactive } from "vue";
import { mapState } from "vuex";
import { useHead } from "@vueuse/head";
import { store } from "./store/index";
import * as stateActions from "./store/stateActions";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if(process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_DSN !== "") {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
export default defineComponent({
	name: "App",
	setup() {
		const siteData = reactive({
			title: `Livecart`,
			description: `Streaming service`,
		});

		const params = new URL(location.href).searchParams;

		if (params.get("locale")) {
			localStorage.setItem("locale", params.get("locale"));
		}

		useHead({
			// Can be static or computed
			title: computed(() => siteData.title),
			meta: [
				{
					name: `description`,
					content: computed(() => siteData.description),
				},
			],
			link: [
				{
					rel: "icon",
					href: require("./assets/favicon-16x16.png"),
					sizes: "16x16",
					type: "image/png",
				},
				{
					rel: "icon",
					href: require("./assets/favicon-32x32.png"),
					sizes: "32x32",
					type: "image/png",
				},
				{
					rel: "apple-touch-icon",
					href: require("./assets/apple-touch-icon.png"),
					sizes: "180x180",
				},
			],
		});
	},

	computed: mapState(["notifications", "roomClient", "componentKey"]),

	mounted() {
    if (typeof navigator.mediaDevices === "undefined") {
      this.$router.push({ name: "unavailable" });
    }
    const params = new URL(location.href).searchParams;
    if(params.get("locale")) {
      this.$i18n.locale = params.get("locale")
    }

		window.addEventListener("online", async () => {
			if (this.$route.name === "room") {
        if (!this.roomClient._protooTransport.closed) {
          await this.roomClient.enableMic();
          await this.roomClient.enableWebcam();
        }
			}
		});

		window.addEventListener("offline", async () => {
			if (this.$route.name === "room") {
        if (!this.roomClient._protooTransport.closed) {
          await this.roomClient.disableMic();
          await this.roomClient.disableWebcam();
        }
        //await this.roomClient.stopRecord();
				//await this.roomClient.close();
			}
		});
    if(typeof(screen) != "undefined" && typeof(screen.orientation) != "undefined") {
      screen.orientation.addEventListener('change', () => {
        const mql = screen.orientation.type.indexOf("portrait");
        if (mql === 0) {
          store.dispatch(stateActions.setOrientation("portrait"));
        } else {
          store.dispatch(stateActions.setOrientation("landscape"));
        }
      });
    } else {
      window.addEventListener("orientationchange", () => {
        const mql = window.matchMedia("(orientation: portrait)");

        if (mql.matches) {
          store.dispatch(stateActions.setOrientation("portrait"));
        } else {
          store.dispatch(stateActions.setOrientation("landscape"));
        }
      });
    }

		document.addEventListener(
			"visibilitychange",
			async () => {
				switch (document["visibilityState"]) {
					case "visible":
						if (
							this.$route.name === "room" &&
							!store.state.me.isAdmin
						) {

              await this.roomClient.unmuteMic();
              await this.roomClient.unmuteWebcam()
/*
							store.dispatch("componentKey");
*/
						}

						break;
					case "hidden":
						if (
							this.$route.name === "room" &&
							!store.state.me.isAdmin
						) {

              await this.roomClient.muteMic();
              await this.roomClient.muteWebcam()
              //await this.roomClient.stopRecord();
							/*if (this.roomClient._protooTransport) {
								this.roomClient._protooTransport.close();
							}

							await this.roomClient.close();*/
						}

						break;
				}
			},
			false
		);
	},

	watch: {
		notifications(notification) {
			this.$moshaToast(
				{
					title: notification.title,
					description: notification.text,
				},
				{
					timeout: notification.timeout || 5000,
					type: notification.type || "info", // 'info' | 'danger' | 'warning' | 'success' | 'default'
					showCloseButton: notification.position || true,
					position: notification.position || "top-left",
				}
			);
		},
	},
});
