import * as stateActions from "./stateActions";

import { store } from ".";

export const notify = ({ type, text, title, timeout }: any) => {
  const notification = {
    type,
    title,
    text,
    timeout,
  };

  store.dispatch(stateActions.addNotification(notification));
};
