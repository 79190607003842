const dataConsumers = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "closed") {
          state.dataConsumers = {};
        }
      }

      break;

    case "ADD_DATA_CONSUMER":
      {
        const { dataConsumer } = action.payload;

        state.dataConsumers[dataConsumer.id] = dataConsumer;
      }

      break;

    case "REMOVE_DATA_CONSUMER":
      {
        const { dataConsumerId } = action.payload;
        const newState = { ...state.dataConsumers };

        delete newState[dataConsumerId];

        state.dataConsumers = newState;
      }

      break;
  }
};

export default dataConsumers;
