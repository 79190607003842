import * as requestActions from "../store/requestActions";

import axios from 'axios';
import i18n from '../lang';
export default class ApiService {
    constructor() {

    }

    static async checkToken(token) {
        return axios
            .get(
                process.env.VUE_APP_SERVER_LINK + "/checkToken",
                {
                    headers: {
                        "x-access-token": token
                    }
                }
            );
    }
    static async showMask(roomId: string, maskType: string, maskId: string) {
        return axios
            .post(
                process.env.VUE_APP_SERVER_LINK + "/showMask",
                { roomId, maskType, maskId },
                {
                    headers: {
                        "x-access-token": this.getToken()
                    }
                }
            );
    }

    static getServerLink() {
        return process.env.VUE_APP_SERVER_LINK;
    }

    static getProducts(token) {
        return axios
            .get(
                process.env.VUE_APP_SERVER_LINK + "/products",
                {
                    headers: {
                        "x-access-token": token
                    }
                }
            );
    }

    static async getProduct(id) {
        return axios
            .get(
                process.env.VUE_APP_SERVER_LINK + `/product/${id}`,
            );
    }
    
    static async getProductButtons(product_id) {
        return axios
            .get(
                process.env.VUE_APP_SERVER_LINK + `/product/${product_id}/buttons`,
            );
    }

    static async createOtp(roomId: string) {
        return axios
            .post(
                process.env.VUE_APP_SERVER_LINK + "/otp/create",
                { roomId },
                {
                    headers: {
                        "x-access-token": this.getToken()
                    }
                }
            );
    }

    static async sendPhone(roomId: string, phone: string) {
        return axios
            .post(
                process.env.VUE_APP_SERVER_LINK + "/otp/sendPhone",
                { phone, roomId },
                {
                    headers: {
                        "x-access-token": this.getToken()
                    }
                }
            );
    }

    static async sendCode(roomId: string, code: string) {
        return axios
            .post(
                process.env.VUE_APP_SERVER_LINK + "/otp/sendCode",
                { code, roomId },
                {
                    headers: {
                        "x-access-token": this.getToken()
                    }
                }
            );
    }

    static sendPhoto(photo, roomId) {
        axios
            .post(
                process.env.VUE_APP_SERVER_LINK + "/file",
                { photo, roomId },
                {
                    headers: {
                        "x-access-token": this.getToken()
                    }
                }
            )
            .then(
                (response: any) => {
                    if (response.data?.saved) {
                        requestActions.notify({
                            type: "success",
                            text: i18n.global.t('notifications.photo.save'),
                        });
                    }
                },
                (err) => {
                    requestActions.notify({
                        type: "danger",
                        text: i18n.global.t('notifications.photo.notSave'),
                    });
                }
            );
    }

    static classifier(photo, roomId) {
        axios
            .post(
                process.env.VUE_APP_SERVER_LINK + "/classifier",
                { photo, roomId },
                {
                    headers: {
                        "x-access-token": this.getToken()
                    }
                }
            )
            .then(
                (response: any) => {
                    if (response.data?.success === 'success') {
                        if(response.data?.document_type) {
                            requestActions.notify({
                                type: "success",
                                text: i18n.global.t('documentShow', {text: response.data?.document_type}),
                            });
                        } else {
                            requestActions.notify({
                                type: "success",
                                text: i18n.global.t('documentNotFound'),
                            });
                        }
                    }
                },
                (err) => {
                    requestActions.notify({
                        type: "danger",
                        text: i18n.global.t('unavailable.message'),
                    });
                }
            );
    }

    static getToken() {
        return localStorage.getItem('token');
    }
}