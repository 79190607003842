const peers = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "closed") {
          state.peers = {};
        }
      }

      break;

    case "ADD_PEER":
      {
        const { peer } = action.payload;

        state.peers[peer.id] = peer;
      }

      break;

    case "REMOVE_PEER":
      {
        const { peerId } = action.payload;
        const newState = { ...state.peers };

        delete newState[peerId];

        state.peers = newState;
      }

      break;

    case "SET_PEER_DISPLAY_NAME":
      {
        const { displayName, peerId } = action.payload;
        const peer = state.peers[peerId];

        if (!peer) {
          throw new Error("no Peer found");
        }

        const newPeer = { ...peer, displayName };

        state.peers[newPeer.id] = newPeer;
      }

      break;

    case "ADD_CONSUMER":
      {
        const { consumer, peerId } = action.payload;
        const peer = state.peers[peerId];

        if (!peer) {
          throw new Error("no Peer found for new Consumer");
        }

        const newConsumers = [...peer.consumers, consumer.id];
        const newPeer = { ...peer, consumers: newConsumers };

        state.peers[newPeer.id] = newPeer;
      }

      break;

    case "REMOVE_CONSUMER":
      {
        const { consumerId, peerId } = action.payload;
        const peer = state.peers[peerId];

        if (!peer) {
          return;
        }

        const idx = peer.consumers.indexOf(consumerId);

        if (idx === -1) throw new Error("Consumer not found");

        peer.consumers.splice(idx, 1);

        console.log("peer.consumers", peer.consumers)

        // state.peers[peer.id].consumers = peer.consumers.splice(idx, 1);
      }

      break;

    case "ADD_DATA_CONSUMER":
      {
        const { dataConsumer, peerId } = action.payload;

        if (!peerId) {
          return;
        }

        const peer = state.peers[peerId];

        if (!peer) throw new Error("no Peer found for new DataConsumer");

        const newDataConsumers = [...peer.dataConsumers, dataConsumer.id];
        const newPeer = { ...peer, dataConsumers: newDataConsumers };

        state.peers[newPeer.id] = newPeer;
      }

      break;

    case "REMOVE_DATA_CONSUMER":
      {
        const { dataConsumerId, peerId } = action.payload;

        if (!peerId) {
          return;
        }

        const peer = state.peers[peerId];

        if (!peer) {
          return;
        }

        const idx = peer.dataConsumers.indexOf(dataConsumerId);

        if (idx === -1) throw new Error("DataConsumer not found");

        const newDataConsumers = peer.dataConsumers.slice();

        newDataConsumers.splice(idx, 1);

        const newPeer = { ...peer, dataConsumers: newDataConsumers };

        state.peers[newPeer.id] = newPeer;
      }

      break;
  }
};

export default peers;
