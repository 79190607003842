import consumers from "./modules/consumers";
import { createStore } from "vuex";
import dataConsumers from "./modules/dataConsumers";
import dataProducers from "./modules/dataProducers";
import me from "./modules/me";
import notifications from "./modules/notifications";
import peers from "./modules/peers";
import producers from "./modules/producers";
import room from "./modules/room";

export const store = createStore({
  state: {
    roomClient: {},
    room: {
      url: null,
      state: "new", //TODO: type new/connecting/connected/disconnected/closed,
      volume: {},
      activeSpeakerId: null,
      statsPeerId: null,
      faceDetection: false,
      record: false,
      isRefresh: false,
    },
    me: {
      isAdmin: 0,
      id: null,
      displayName: null,
      displayNameSet: false,
      device: null,
      canSendMic: false,
      canSendWebcam: false,
      canChangeWebcam: false,
      webcamInProgress: false,
      shareInProgress: false,
      audioOnly: false,
      audioOnlyInProgress: false,
      micMuted: false,
      webcamMuted: false,
      restartIceInProgress: false,
      recordInProgress: false,
      phoneFormOpened: false,
      product: null,
      codeFormOpened: false,
      orientation: null,
      clientOrientation: 'landscape',
      maskType: null,
      geo: null,
    },
    producers: {},
    dataProducers: {},
    peers: {},
    consumers: {},
    dataConsumers: {},
    notifications: [],
    componentKey: 0,
  },

  actions: {
    room,
    me,
    producers,
    dataProducers,
    peers,
    consumers,
    dataConsumers,
    notifications,
    roomClient({ state }, client) {
      state.roomClient = client;
    },
    componentKey({ state }) {
      state.componentKey++;
    },
  },

  getters: {
    me(state) {
      return state.me;
    },

    producers(state) {
      return state.producers;
    },

    orientation(state) {
      return state.me.orientation;
    },

    geo(state) {
      return state.me.geo;
    },

    clientOrientation(state) {
      return state.me.clientOrientation;
    },

    consumers(state) {
      return state.consumers;
    },

    volume(state) {
      return state.room.volume;
    },

    room(state) {
      return state.room;
    },

    roomClient(state) {
      return state.roomClient;
    },

    notifications(state) {
      return state.notifications;
    },

    peers(state) {
      return state.peers;
    },

    phoneFormOpened(state) {
      return state.me.phoneFormOpened;
    },

    product(state) {
      return state.me.product;
    },

    codeFormOpened(state) {
      return state.me.codeFormOpened;
    },

    componentKey(state) {
      return state.componentKey;
    },
  },
});
