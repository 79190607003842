import {store} from "@/store";

const me = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "closed") {
          state.me = {
            ...state.me,
            webcamInProgress: false,
            shareInProgress: false,
            audioOnly: false,
            audioOnlyInProgress: false,
            audioMuted: false,
            restartIceInProgress: false,
          };
        }
      }

      break;

    case "SET_ME":
      {
        const { peerId, displayName, displayNameSet, device } = action.payload;

        state.me.id = peerId;
        state.me.displayName = displayName;
        state.me.displayNameSet = displayNameSet;
        state.me.device = device;
      }

      break;

    case "SET_ADMIN":
      {
        const { isAdmin } = action.payload;

        state.me.isAdmin = isAdmin;
      }

      break;

    case "SET_PRODUCT":
      {
        const { product } = action.payload;

        state.me.product = product;
      }

      break;

    case "SET_MEDIA_CAPABILITIES":
      {
        const { canSendMic, canSendWebcam } = action.payload;

        state.me.canSendMic = canSendMic;
        state.me.canSendWebcam = canSendWebcam;
      }

      break;

    case "SET_CAN_CHANGE_WEBCAM":
      {
        const canChangeWebcam = action.payload;

        state.me.canChangeWebcam = canChangeWebcam;
      }

      break;

    case "SET_WEBCAM_IN_PROGRESS":
      {
        const { flag } = action.payload;

        state.me.webcamInProgress = flag;
      }

      break;

    case "SET_SHARE_IN_PROGRESS":
      {
        const { flag } = action.payload;

        state.me = { ...state.me, shareInProgress: flag };
      }

      break;

    case "SET_DISPLAY_NAME":
      {
        let { displayName } = action.payload;

        if (!displayName) {
          displayName = state.me.displayName;
        }

        state.me.displayName = displayName;
        state.me.displayNameSet = true;
      }

      break;

    case "SET_AUDIO_ONLY_STATE":
      {
        const { enabled } = action.payload;

        state.me.audioOnly = enabled;
      }

      break;

    case "SET_AUDIO_ONLY_IN_PROGRESS":
      {
        const { flag } = action.payload;

        state.me.audioOnlyInProgress = flag;
      }

      break;

    case "SET_MIC_STATE":
      {
        const { enabled } = action.payload;

        state.me.micMuted = enabled;
      }

      break;

    case "SET_WEBCAM_STATE":
      {
        const { enabled } = action.payload;

        state.me.webcamMuted = enabled;
      }

      break;

    case "SET_RESTART_ICE_IN_PROGRESS":
      {
        const { flag } = action.payload;

        state.me.restartIceInProgress = flag;
      }

      break;

    case "SET_RECORD_IN_PROGRESS":
      {
        const { flag } = action.payload;

        state.me.recordInProgress = flag;
      }

      break;

    case "SET_PHONE_FORM_OPENED":
      {
        const { flag } = action.payload;

        state.me.phoneFormOpened = flag;
      }

      break;

    case "SET_CODE_FORM_OPENED":
      {
        const { flag } = action.payload;

        state.me.codeFormOpened = flag;
      }

      break;

    case "SET_ORIENTATION":
      {
        const { flag } = action.payload;

        state.me.orientation = flag;
      }

      break;

    case "SET_GEO":
      {
        const { flag } = action.payload;
        state.me.geo = flag;
      }

      break;

    case "SET_CLIENT_ORIENTATION":
      {
        const { flag } = action.payload;

        state.me.clientOrientation = flag;
      }

      break;

    case "SET_MASK":
    {
      const { flag } = action.payload;
      state.me.product = null;
      state.me.maskType = flag;
    }

      break;

  }
};

export default me;
