const notifications = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "ADD_NOTIFICATION":
      {
        const { notification } = action.payload;

        state.notifications = notification;
      }

      break;
  }
};

export default notifications;
