const dataProducers = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "closed") {
          state.dataProducers = {};
        }
      }

      break;

    case "ADD_DATA_PRODUCER":
      {
        const { dataProducer } = action.payload;

        state.dataProducers[dataProducer.id] = dataProducer;
      }

      break;

    case "REMOVE_DATA_PRODUCER":
      {
        const { dataProducerId } = action.payload;
        const newState = { ...state.dataProducers };

        delete newState[dataProducerId];

        state.dataProducers = newState;
      }

      break;
  }
};

export default dataProducers;
